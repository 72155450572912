import React from 'react';
import { Link } from 'gatsby';
import tw from 'tailwind.macro';
import styled from '@emotion/styled';
import Container from '../../components/Container';

const Wrapper = styled.section`
  ${tw`
    py-4 pb-6 border-t-4 border-b-4
  `}
`;

const Flex = styled.div`
  ${tw`
    justify-start flex flex-wrap items-center
    md:justify-between
  `}
`;

const StyledLink = styled(Link)`
  ${tw`
    inline-block px-6 py-2 border border-current rounded
    hover:text-white
  `}
  cursor:pointer;
`;

const StyledH2 = tw.h2`
  text-4xl
  font-display uppercase font-bold leading-none mb-4
  md:text-5xl
`;

const StyledH3 = styled.h3`
  ${tw`
    text-darkBlue font-body text-xl mb-2
  `}
  margin-top: -1rem;
  letter-spacing: -0.01rem;
`;


const DonationBar = ({heading, subheading, color, link, linkText}) => {
    const styles = (color) => {
      switch(color){
        case 'brandRed':
          return "border-brandRed text-brandRed";
        case 'lightBlue':
          return "border-lightBlue text-lightBlue";
        case 'darkBlue':
          return "border-darkBlue text-darkBlue";
        default:
          return "border-darkBlue text-darkBlue";
      }
    };
    return (
      <Wrapper className={styles(color)}>
        <Container>
          <Flex>
            <div>
              <StyledH2>{heading}</StyledH2>
              <StyledH3>{subheading}</StyledH3>
            </div>
            <StyledLink to={link} className={`hover:bg-${color}`}>{linkText}</StyledLink>
          </Flex>
        </Container>
      </Wrapper>
    )
};

export default DonationBar;