import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import tw from 'tailwind.macro';
import styled from '@emotion/styled';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 50vh; /* if you don't want it to take up the full screen, reduce this number */
  overflow: hidden;
  background: linear-gradient(to top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(0,173,239,0.65) 100%), url("https://d2u86e95w5oqta.cloudfront.net/images/lake-erie-sm.jpg?mtime=20200524110402") no-repeat center center scroll;
  background-size: cover;
`;

const Subheading = styled.span`
  ${tw`
    block my-0 py-2 px-4 font-body text-darkBlue font-black text-xl
  `}
  letter-spacing: .05rem;
  margin-bottom: -.5rem;
`;

 const StyledH2 = styled.h2`
  border-bottom: 6px solid;
  ${tw`
      font-display font-black text-white text-4xl border-lightBlue relative mt-2 mb-4`
    }
  &::after{
    position:absolute;
    content: '';
    width: 100%;
    height:3px;
    background-color: #1e1f57;
    /* border-bottom: 8px solid #00adef; */
    bottom:0;
    left: 0;
  }
 `;

const StyledLink = styled(AnchorLink)`
  ${tw`
    block mt-8 px-6 py-2 bg-white border border-lightBlue rounded
    hover:bg-lightBlue hover:text-white
  `}
  cursor:pointer;
`;

const DistrictHero = () => (
    <Wrapper>
        <div className="mt-8">
          <Subheading>Working for working class Ohioans</Subheading>
          <StyledH2>Learn About Ohio's Senate District 2</StyledH2>
        </div>
        <StyledLink to="/about#about-the-district">Learn More</StyledLink>
    </Wrapper>
);

export default DistrictHero;