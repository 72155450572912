import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from '@emotion/styled';
import tw from 'tailwind.macro';

 const Wrapper = tw.div`
  h-full flex flex-col justify-center
 `;

 const HeroImage = styled.section`
  ${tw`
    h-1 min-h-50 lg:min-h-75
  `}
  background-position: 90% center;
  background-size: cover;
  background-repeat: no-repeat;
  position:relative;
  display:block;
  min-height: 75vh;

 `;

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "header_new_official-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroImage: homeJson {
        data {
          bannerImage {
            url
            alt
          }
        }
      }
    }
  `)

  return (
    <HeroImage
      style={{backgroundImage: `url("${data.heroImage.data[0].bannerImage.url}")`}}
    >
      <Wrapper className={"container mx-auto max-w-2xl"}>
        <h1 className={"sr-only"}>Joel O'Dorisio for Bowling Green City Council</h1>
        <Img fluid={data.logoImage.childImageSharp.fluid} alt="Joel O'Dorisio for Bowling Green City Council - Qualified, compassionate leadership." />

      </Wrapper>
      
    </HeroImage>
      
    
  )
}

export default Hero
