import React from "react"
import { Link } from "gatsby"
import styled from '@emotion/styled';
import tw from 'tailwind.macro';


const Wrapper = styled.article`
  ${tw`
  relative pt-16 flex flex-col justify-end items-center text-center rounded bg-cover bg-no-repeat border border-solid
  `}
  min-height: 200px;
  transform: scale(1);
  transition: transform .1s ease-out;
  will-change: transform;
  box-shadow:  -20px -20px 60px #d2d5d6, 
              20px 20px 60px #ffffff;
  &:hover {
    will-change:transform;
    transform: scale(1.025);
    transition: transform .1s ease-out;
  }

  &::after{
    position: absolute;
    content: '';
    top:0;
    left:0;
    height:100%;
    width:100%;
    background: rgb(0,173,239);
    background: linear-gradient(163deg, rgba(0,173,239,1) 0%, rgba(30,31,87,1) 100%);
    opacity: 0.5;
    z-index:-1;
  }
`;


 const StyledH4 = styled.h4`
  ${tw`
     p-2 mb-4 block font-bold text-2xl text-center bg-gray-100 w-full
  `}
 `;


const IssueCard = ({title, slug, image}) => {
    return (
      <Link to={`/issues/${slug}`}>
        <Wrapper style={{backgroundImage: `url(${image}`}}>
          <StyledH4>{title}</StyledH4>
        </Wrapper>
      </Link>
  )
}

export default IssueCard
