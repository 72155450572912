import React from "react"
import tw from 'tailwind.macro';
import styled from '@emotion/styled';

 const ContainerStyles = styled.div`
  ${tw`
    mx-auto px-8 md:px-12
  `}
 `;

const Container = ({children}) => (
  <ContainerStyles className={'container'}>
    {children}
  </ContainerStyles>
)

export default Container
