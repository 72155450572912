import React from 'react';
import tw from 'tailwind.macro';
import styled from '@emotion/styled';

const StyledSpacer = styled.div`
  ${tw`
    my-12
  `}
`;


const Spacer = ({children}) => (
    <StyledSpacer>
      {children}
    </StyledSpacer>
);

export default Spacer;