import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled';
import tw from 'tailwind.macro';

import IssueCard from './IssueCard';
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

 const Wrapper = tw.section`
 
 `;

 const Container = tw.div`
  mx-auto py-12 px-8 md:px-12 
 `;

 const StyledH2 = styled.h2`
  border-bottom: 6px solid;
  ${tw`
      font-display font-black text-4xl border-lightBlue max-w-xs relative`
    }
  &::after{
    position:absolute;
    content: '';
    width: 100%;
    height:3px;
    background-color: #1e1f57;
    /* border-bottom: 8px solid #00adef; */
    bottom:0;
    left: 0;
  }
 `;

const SliderWrapper = styled.div`
  ${tw`
    my-12
  `}
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem;

    @media screen and (max-width: 500px){
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
`;

const IssuesSlider = ({heading, wrapperBg}) => {
  const data = useStaticQuery(graphql`
    query {
      issues: allIssuesJson {
        nodes {
          data {
            title
            slug
            subtitle
            excerpt
            image {
              url
              alt
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper className={wrapperBg}>
      <Container className={"container"}>
        {heading && 
          <StyledH2>{heading}</StyledH2>
        }
        <SliderWrapper>

          {data.issues.nodes[0].data.map((issue, index) => {
            if(issue.title && issue.slug){
              return  <IssueCard title={issue.title} slug={issue.slug} subtitle={issue.subtitle} excerpt={issue.excerpt} image={issue.image.url} key={index} />
            } else return
          })}
          
        </SliderWrapper>
      </Container>
    </Wrapper>
  )
}

export default IssuesSlider
