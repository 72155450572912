import React from 'react';
import tw from 'tailwind.macro';
import styled from '@emotion/styled';
import Container from '../components/Container';
import Logomark from '../images/circle-logo_white.svg';

const Wrapper = styled.section`
  ${tw`
    bg-darkBlue py-4 pb-6 border-t-4 border-b-4 border-lightBlue relative
  `}
  z-index: 2;
  
  &:after{
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: .1;
    content: '';
    left: 0;
    top: 0;
    z-index: 1;
    background-repeat: no-repeat;
    background-image: url(${Logomark});
    background-size: 200px;
    background-position: 0% 50%;
  }

  & *{
    z-index:2;
  }
`;

const Flex = styled.div`
  ${tw`
    justify-center flex flex-wrap items-center
    md:justify-between
  `}
`;

const StyledOl = styled.ol`
  ${tw`
    flex flex-wrap justify-center
    list-none m-0 p-0
    md:block
  `}
`;

const StyledLi = styled.li`
  ${tw`
    inline-block mx-2 mb-4
    md:mb-0
  `}
`;

const StyledLink = styled.a`
  ${tw`
    inline-block px-6 py-2 bg-white border border-lightBlue rounded
    hover:bg-lightBlue hover:text-white
  `}
  cursor:pointer;
`;

const StyledH2 = tw.h2`
  text-white font-display uppercase text-5xl font-bold
`;

const StyledH3 = styled.h3`
  ${tw`
    text-lightBlue font-body text-xl mb-2
  `}
  margin-top: -1rem;
`;

const DonationAmounts = ["5", "10", "15", "25", "50"];


const DonationBar = () => (
    <Wrapper>
      <Container>
        <Flex>
          <div>
            <StyledH2>Support</StyledH2>
            <StyledH3>Qualified, compassionate leadership</StyledH3>
          </div>
          
          <StyledOl>
            {DonationAmounts.map((amount) => (
              <StyledLi>
                <StyledLink 
                  href={`https://secure.actblue.com/donate/joelforbgcouncil?amount=${amount}&recurring=1`} 
                  title={`Donate $${amount}`} key={amount}>
                    ${amount}
                  </StyledLink>
              </StyledLi>
            ))}
            <StyledLi>
                <StyledLink 
                  href={`https://secure.actblue.com/donate/joelforbgcouncil?amount=other`} 
                  title={`Donate Other Amount`} key="other">
                    Other
                  </StyledLink>
              </StyledLi>
          </StyledOl>
        </Flex>
      </Container>
    </Wrapper>
);

export default DonationBar;