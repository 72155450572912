import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import AlertBar from '../components/global/AlertBar';
import AboutFeature from '../components/homepage/AboutFeature';
import IssuesSlider from '../components/IssuesSlider'
import DonationBar from '../components/donationBar';
import DistrictHero from '../components/homepage/DistrictHero'
import CallToAction from '../components/global/CallToAction';
import Spacer from '../components/global/Spacer'
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    {/* <TextColumn text={data.bio.html} heading={data.bio.frontmatter.heading} multicolumn={true} /> */}
    <AlertBar heading="COVID-19 Resources" subheading="Local and regional resources related to COVID-19" link="/covid-19-resources" color="brandRed" linkText="View All" />
    <AboutFeature />
    <DonationBar />
    <IssuesSlider heading={"On the Issues"} wrapperBg={"bg-white"} />
    {/* <TextColumn text={data.donate.html} heading={data.donate.frontmatter.heading} multicolumn={false} /> */}
    <Spacer>
      <CallToAction/>
    </Spacer>
    
  </Layout>
);

export default IndexPage
