import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import styled from '@emotion/styled';
import tw from 'tailwind.macro';

 const Wrapper = styled.div`
 `;

const Subheading = styled.span`
  ${tw`
    block my-0 font-body text-lightBlue font-light text-xl
  `}
  letter-spacing: .05rem;
  margin-bottom: -.5rem;
`;

 const StyledH2 = styled.h2`
  border-bottom: 6px solid;
  ${tw`
      font-display font-black text-darkBlue text-4xl border-lightBlue max-w-xs relative mt-0 mb-4`
    }
  &::after{
    position:absolute;
    content: '';
    width: 100%;
    height:3px;
    background-color: #1e1f57;
    /* border-bottom: 8px solid #00adef; */
    bottom:0;
    left: 0;
  }
 `;

 const ContentWrapper = styled.div`
  ${tw`
    py-16 px-8 md:px-16
  `}
 `;

const StyledLink = styled(Link)`
${tw`
  block font-display font-black text-darkBlue text-3xl mt-6
  hover:text-lightBlue
`}

&:hover {
  &>svg{
    ${tw`text-darkBlue`}
  }
}
`;

const AboutFeature = () => {
  const data = useStaticQuery(graphql`
    query {
      homeJson {
        data {
          aboutFeature {
            text
            image {
              url
              alt
            }
          }
        }
      }
    }
  `)

  return (
      <Wrapper className={`flex flex-col md:grid md:grid-cols-2 md:items-center`}>
        {/* <Img fluid={data.logoImage.childImageSharp.fluid} alt="Joel O'Dorisio for Bowling Green City Council" /> */}
        <ContentWrapper>
          <Subheading>Educator, father, labor leader</Subheading>
          <StyledH2>Meet Joel</StyledH2>
          <p>
            Joel grew up in Columbus, OH where he benefited from a strong public education. He continued his education at Ohio State University, where he majored in fine arts. Joel and his family have lived in BG since 2006. As a father, Joel has always stressed the importance of public service and standing up for others. Joel is an educator, entrepreneur, dedicated father, and union organizer.
          </p>
          <StyledLink to={'about'}>
            Learn More <FontAwesomeIcon icon={faAngleRight} className="text-brandRed ml-2"/></StyledLink>
        </ContentWrapper>
        {/* <ImageWrapper style={{backgroundImage: `url(${data.homeJson.data[0].aboutFeature.image.url})`}} aria-label={`${data.craftHomepage.aboutFeature.image.alt}`} /> */}
         <img src={data.homeJson.data[0].aboutFeature.image.url} alt={data.homeJson.data[0].aboutFeature.image.alt} />

      </Wrapper>
      
    
  )
}

export default AboutFeature
